import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
  DestroyRef,
  ChangeDetectorRef,
} from '@angular/core';
import {
  TuiComboBoxComponent,
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiFilterByInputPipeModule,
  TuiInputModule,
  TuiStringifyContentPipeModule,
} from '@taiga-ui/kit';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  TuiDataListModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiPrimitiveTextfieldModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import {
  TuiAutoFocusModule,
  TuiFocusedModule,
  TuiFocusVisibleModule,
  TuiLetModule,
  tuiPure,
  TuiStringHandler,
  TuiValueChangesModule,
} from '@taiga-ui/cdk';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  Subject,
  tap,
  withLatestFrom,
  combineLatest,
  shareReplay,
  fromEvent,
  delay,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IndexChangeDirective } from '../../../directives/index-change.directive';

@Component({
  selector: 'app-autocomplete',
  standalone: true,
  imports: [
    TuiInputModule,
    TuiDataListWrapperModule,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    AsyncPipe,
    TuiStringifyContentPipeModule,
    TuiComboBoxModule,
    TuiDataListModule,
    TuiLetModule,
    FormsModule,
    TuiHostedDropdownModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    TuiScrollbarModule,
    IndexChangeDirective,
    NgIf,
    TuiFilterByInputPipeModule,
    CdkVirtualForOf,
    TuiValueChangesModule,
    TuiFocusedModule,
    TuiFocusVisibleModule,
    TuiPrimitiveTextfieldModule,
    TuiDropdownModule,
    TuiAutoFocusModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent<T>
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  @ViewChild('comboBoxComponent', { read: ElementRef })
  comboBoxComponent: ElementRef;

  @ViewChild('comboBoxComponent', { read: TuiComboBoxComponent })
  comboBox: TuiComboBoxComponent<any>;

  @ViewChild(CdkVirtualScrollViewport, { read: CdkVirtualScrollViewport })
  viewport: CdkVirtualScrollViewport;

  formControl: FormControl;

  @Input({ required: false })
  itemText: keyof T = 'text' as keyof T;

  @Input({ required: false })
  autoSelectFirst = true;

  @Input({ required: false })
  strict = true;

  @Output()
  valueChanged = new EventEmitter<Nullable<string>>();

  @Input({ required: false })
  itemSearch: keyof T = '' as keyof T;

  @Input({ required: false })
  displayValue: keyof T = '' as keyof T;

  @Input({ required: false })
  size: 's' | 'm' | 'l' = 'm';

  @Input({ required: false })
  label = '';

  @Input({ required: false })
  labelOutside = true;

  @Input({ required: false })
  prefix = '';

  @Input({ required: false })
  componentClass = '';

  searchValue$ = new BehaviorSubject<string | null>('');
  initialValue$ = new BehaviorSubject<string>('');
  isDropdownOpen$ = new BehaviorSubject(false);
  dropdownWidth$ = new BehaviorSubject<number>(100);
  items$ = new BehaviorSubject<T[]>([]);
  itemsMap$ = new BehaviorSubject<Map<string, T>>(new Map());
  itemValue$ = new BehaviorSubject<keyof T>('value' as keyof T);

  itemsFiltered$ = combineLatest([
    this.searchValue$.pipe(distinctUntilChanged()),
    this.items$,
  ]).pipe(
    map(([value, items]) => {
      if (value === '') {
        return items;
      }
      if (value === null) {
        return this.getSingleItemArray();
      }
      return this.getFilteredItemsByValue(value);
    }),
    tap(() => this.recalculateDropDownWidth$.next(0)),
  );

  itemHeight$ = this.itemsFiltered$.pipe(
    map(items => (this.isValueSelected ? 1 : items.length)),
    distinctUntilChanged(),
    map(length => this.getViewportHeight(length)),
    shareReplay(),
  );

  recalculateDropDownWidth$ = new Subject<number>();

  searchValueHandler$ = this.itemsMap$.pipe(
    map(itemsMap => this.getSearchValueInMap(itemsMap)),
  );

  constructor(
    private readonly injector: Injector,
    private readonly destroyRef: DestroyRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  onSearch(searchValue: string | null) {
    this.searchValue$.next(searchValue);
  }

  onEnterPress() {
    this.completeIfPossible();
  }

  onFocusChange(focused: boolean) {
    if (!focused) {
      const formControlValue = this.formControl.getRawValue();
      this.closeDropdown();
      if (
        this.autoSelectFirst &&
        this.searchValue$ !== null &&
        this.searchValue$.value !== this.initialValue$.value
      ) {
        this.completeIfPossible();
      }
      if (
        (this.initialValue$.value?.toString() ?? '') !==
        (formControlValue?.toString() ?? '')
      ) {
        this.valueChanged.emit(formControlValue);
        this.initialValue$.next(formControlValue);
      }
      this.clearSearchValue();
    }
  }

  onScroll(startIndex: number) {
    this.recalculateDropDownWidth$.next(startIndex);
  }

  onDropdownOpenChange() {
    this.isDropdownOpen$
      .pipe(
        filter(opened => opened),
        tap(() => this.recalculateDropDownWidth$.next(0)),
        withLatestFrom(this.itemsFiltered$),
        delay(0),
        tap(([, items]) => {
          this.viewport.scrollToIndex(this.getSelectedValueIndex(items));
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onKeyDownEnter() {
    fromEvent<KeyboardEvent>(this.comboBoxComponent.nativeElement, 'keydown')
      .pipe(
        filter(e => e.key === 'Enter'),
        tap(() => {
          this.isDropdownOpen$.next(!this.isDropdownOpen$.value);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  getSelectedValueIndex(items: T[]) {
    return (
      items.findIndex(
        i => i[this.itemValue$.value] === this.formControl.getRawValue(),
      ) ?? 0
    );
  }

  getSearchValueInMap(map: Map<string, T>): TuiStringHandler<unknown> {
    return (identity: any): string => {
      const identityValue = identity?.toString();
      const item = map.get(identityValue);
      if (!item) {
        return '';
      }
      const key = this.displayValue || this.itemValue;
      return item[key]?.toString() ?? '';
    };
  }

  getSingleItemArray(): T[] {
    const previousInternalValueKey = 'previousInternalValue';

    const inputValue =
      this.formControl.updateOn === 'blur'
        ? this.comboBox[previousInternalValueKey]
        : this.formControl.getRawValue()?.toString();

    const item = this.itemsMap$.value.get(inputValue ?? '');
    return item ? [item] : [];
  }

  get isValueSelected() {
    return this.searchValue$.value === null;
  }

  strictMatcher(t: unknown, e: unknown, stringify: (value: unknown) => string) {
    return stringify(t).toLowerCase() === e?.toString().toLowerCase();
  }

  identityMatcher(search: unknown, formControlValue: unknown) {
    return (
      search?.toString().toLowerCase() ===
      formControlValue?.toString().toLowerCase()
    );
  }

  getFullItemsMap(items: T[] | null) {
    const identity: Array<[string, T]> =
      items?.map(item => {
        const id = item[this.itemValue]?.toString() ?? '';
        return [id, item];
      }) ?? [];
    return new Map(identity);
  }

  get items(): T[] {
    return this.items$.value;
  }

  @Input({
    required: true,
  })
  set items(value: T[] | null) {
    this.items$.next(value ?? []);
    this.recalculateDropDownWidth$.next(0);
  }

  @tuiPure
  get itemSize() {
    if (this.size === 'm') {
      return 40;
    }
    if (this.size === 's') {
      return 32;
    }
    return 40;
  }

  get itemValue() {
    return this.itemValue$.value;
  }

  @Input({ required: false })
  set itemValue(value: keyof T) {
    this.itemValue$.next(value);
  }

  openDropdown() {
    this.isDropdownOpen$.next(true);
  }

  closeDropdown() {
    this.isDropdownOpen$.next(false);
  }

  getViewportHeight(length: number | undefined) {
    const calculatedHeight = (length ?? 1) * 40 + 8;
    const maxHeight = 200;
    return Math.min(maxHeight, calculatedHeight);
  }

  completeIfPossible() {
    if (this.searchValue$.value) {
      const [firstSelected] = this.getFilteredItemsByValue(
        this.searchValue$.value,
      );
      this.selectOption(firstSelected);
    }
  }

  selectOption(item: T): void {
    const value = item ? item[this.itemValue] : this.formControl.defaultValue;
    this.formControl.markAsDirty();
    this.formControl.setValue(value);
  }

  createIdentityMap() {
    this.items$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.itemsMap$.next(this.getFullItemsMap(this.items$.value));
    });
  }

  recalculateDropdownWidth() {
    this.recalculateDropDownWidth$
      .pipe(
        filter(() => this.isDropdownOpen$.value),
        withLatestFrom(this.itemsFiltered$),
        tap(([index, itemsFiltered]) => {
          const items = itemsFiltered.length ? itemsFiltered : this.items;
          const visible = items
            .slice(index, Math.min(index + 15, items.length))
            .map(i => i[this.itemText]?.toString().length ?? 0);
          const currentMaxWidth = Math.max(...visible) * 10 + 15;
          this.dropdownWidth$.next(
            Math.max(this.dropdownWidth$.value, currentMaxWidth),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit() {
    const ngControl = this.injector.get(NgControl);
    this.formControl = (ngControl as FormControlDirective).form;

    this.recalculateDropdownWidth();
    this.createIdentityMap();
    this.onDropdownOpenChange();
  }

  getFilteredItemsByValue(value: string) {
    const key = this.itemSearch || this.itemValue;
    return this.items.filter(item =>
      item[key]
        ?.toString()
        .toLowerCase()
        .includes(value?.toString().toLowerCase()),
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  clearSearchValue() {
    this.searchValue$.next('');
  }

  writeValue(value: any): void {
    this.initialValue$.next(value ?? '');
  }

  ngAfterViewInit() {
    this.onKeyDownEnter();

    setTimeout(() => {
      const minWidth =
        this.comboBoxComponent.nativeElement.getBoundingClientRect().width;
      this.dropdownWidth$.next(minWidth);
    });
  }
}
